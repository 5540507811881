import { isElectricVehicle } from '@finn/auto-ui/utils/pdp';
import { Breadcrumb } from '@finn/ua-auth';
import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import {
  FilterKey,
  FiltersResponse,
  FilterValuesObject,
} from '@finn/ua-vehicle';
import { parsePathAndLocale, useCurrentLocale } from '@finn/ui-utils';
import { trim } from 'lodash';
import { IntlShape, useIntl } from 'react-intl';
import useSWR from 'swr';

import {
  serializeFiltersToPath,
  useFiltersResponse,
} from '~/modules/products/filters';
import { getCosmicObjectWithApi } from '~/services/cosmic';
import { PLPPageData, SeoHeaderData } from '~/types/cosmicModules';
import { AvailableFilters, Filters } from '~/types/filter';
import { getInitialFilterKeysMap } from '~/utils/filter';
import {
  getAdvisoryContentSlug,
  getHeadline,
  getSingleSelectItem,
} from '~/utils/plp/plp';

const addFilterBreadcrumb = (
  breadcrumbs: Breadcrumb[],
  selectedFilter: string | boolean,
  filtersToExclude: object,
  filters: FilterValuesObject,
  { locale }: IntlShape,
  allAvailableFilters: FiltersResponse,
  defaultValue = undefined
): Breadcrumb[] => {
  const breadcrumbsArray = breadcrumbs;
  if (selectedFilter) {
    const filterURL =
      breadcrumbsArray.length > 0
        ? serializeFiltersToPath(
            { ...filters, ...filtersToExclude },
            BASE_SUBSCRIPTION_PATH,
            locale,
            allAvailableFilters
          )
        : undefined;
    breadcrumbsArray.unshift({
      title: defaultValue ?? (selectedFilter as string),
      url: filterURL && `/${locale}${filterURL}`,
    });
  }

  return breadcrumbsArray;
};

export const getCustomTitleAndSeoHeader = (
  routerPath: string,
  pageData: PLPPageData
): { title: string; seoHeader: SeoHeaderData } => {
  const entries =
    pageData?.metadata?.custom_title_and_header_entries?.metadata
      ?.plp_custom_title_and_header_entries || [];
  let title = '';
  let seoHeader;
  for (let i = 0; i < entries.length; i++) {
    const pathAndLocale = parsePathAndLocale(entries[i].plp_url);
    if (pathAndLocale.path === trim(routerPath, '/')) {
      title = entries[i].title_for_matching_url;
      seoHeader = entries[i].seo_header_for_matching_url;
      break;
    }
  }

  return { title, seoHeader };
};

type GetBreadcrumbsProps = {
  isOutOfStockPage: boolean;
  filters: FilterValuesObject;
  allAvailableFilters: FiltersResponse;
  i18n: IntlShape;
  locale: string;
};

export const getBreadcrumbs = ({
  isOutOfStockPage,
  filters,
  allAvailableFilters,
  i18n,
  locale,
}: GetBreadcrumbsProps): Breadcrumb[] => {
  const homeTitle = i18n.formatMessage({ id: 'general.home' });
  const defaultHeadline = i18n.formatMessage({ id: 'plp.defaultTitle' });
  let breadcrumbs = [];

  const selectedBrand = getSingleSelectItem(filters, FilterKey.BRANDS);
  const selectedModel =
    selectedBrand && getSingleSelectItem(filters, FilterKey.MODELS);
  const selectedFuelType = getSingleSelectItem(filters, FilterKey.FUELS);
  const selectedCarType = getSingleSelectItem(filters, FilterKey.CAR_TYPES);

  if (selectedFuelType) {
    breadcrumbs.unshift({ title: selectedFuelType });
  }

  breadcrumbs = addFilterBreadcrumb(
    breadcrumbs,
    selectedCarType,
    { fuels: null },
    filters,
    i18n,
    allAvailableFilters
  );

  breadcrumbs = addFilterBreadcrumb(
    breadcrumbs,
    selectedModel,
    { cartypes: null, fuels: null },
    filters,
    i18n,
    allAvailableFilters
  );

  breadcrumbs = addFilterBreadcrumb(
    breadcrumbs,
    selectedBrand,
    { models: null, cartypes: null, fuels: null },
    filters,
    i18n,
    allAvailableFilters
  );

  breadcrumbs = addFilterBreadcrumb(
    breadcrumbs,
    isOutOfStockPage,
    { brands: null, models: null },
    filters,
    i18n,
    allAvailableFilters,
    defaultHeadline
  );

  breadcrumbs.unshift({ title: homeTitle, url: `/${locale}` });

  return breadcrumbs;
};

type GetTitleProps = {
  filters: FilterValuesObject;
  i18n: IntlShape;
  routerPath: string;
  pageData: PLPPageData;
};

export const getTitle = ({
  filters,
  i18n,
  routerPath,
  pageData,
}: GetTitleProps) => {
  const customTitle = getCustomTitleAndSeoHeader(routerPath, pageData).title;
  if (customTitle) {
    return customTitle;
  }

  const defaultHeadline = i18n.formatMessage({ id: 'plp.defaultTitle' });
  const autoAboHeadline = i18n.formatMessage({ id: 'plp.headline' });
  const electricHeadline = i18n.formatMessage({ id: 'plp.electricTitle' });
  const dealsHeadline = i18n.formatMessage({ id: 'plp.discountsTitle' });
  const hitchHeadline = i18n.formatMessage({ id: 'plp.hitchTitle' });
  const youngDriverHeadline = i18n.formatMessage({
    id: 'plp.youngDriverTitle',
  });

  const isPLPFiltered = Object.keys(filters).length > 0;

  const isElectric =
    filters[FilterKey.FUELS]?.length === 1 &&
    isElectricVehicle(filters[FilterKey.FUELS][0]);

  const numFiltersApplied = Object.keys(filters).length;

  const selectedBrand = getSingleSelectItem(filters, FilterKey.BRANDS);
  const selectedModel = getSingleSelectItem(filters, FilterKey.MODELS);
  const selectedFuelType = getSingleSelectItem(filters, FilterKey.FUELS);
  const selectedCarType = getSingleSelectItem(filters, FilterKey.CAR_TYPES);
  const selectedTerm = getSingleSelectItem(filters, FilterKey.TERMS);
  const selectedDeals = !!filters.has_deals;

  if (selectedBrand && selectedModel) {
    return `${selectedBrand} ${selectedModel} ${autoAboHeadline}`;
  } else if (selectedBrand && selectedFuelType && numFiltersApplied === 2) {
    return `${selectedBrand} ${selectedFuelType} ${autoAboHeadline}`;
  } else if (selectedBrand && selectedCarType && numFiltersApplied === 2) {
    return `${selectedBrand} ${selectedCarType} ${autoAboHeadline}`;
  } else if (selectedBrand) {
    return `${selectedBrand} ${autoAboHeadline}`;
  } else if (isElectric) {
    return electricHeadline;
  } else if (selectedCarType) {
    return `${selectedCarType} ${autoAboHeadline}`;
  } else if (selectedFuelType) {
    return `${selectedFuelType} ${autoAboHeadline}`;
  } else if (filters.has_hitch) {
    return hitchHeadline;
  } else if (filters.is_young_driver) {
    return youngDriverHeadline;
  } else if (selectedTerm) {
    const monthsText = `${selectedTerm} ${i18n.formatMessage({
      id: 'plp.months',
    })}`;
    const flexText = i18n.formatMessage({ id: 'plp.flex' });

    return `${selectedTerm > 1 ? monthsText : flexText} ${autoAboHeadline}`;
  } else if (selectedDeals) {
    return dealsHeadline;
  } else if (isPLPFiltered) {
    return autoAboHeadline;
  }

  return defaultHeadline;
};

export const useAdvisoryContent = (locale: string, filter: Filters) => {
  const advisoryContentCosmicSlug = getAdvisoryContentSlug(filter);
  const hasAdvisoryContent = !advisoryContentCosmicSlug.includes('fallback');

  return useSWR(
    hasAdvisoryContent && {
      slug: advisoryContentCosmicSlug,
      showMetafields: true,
      locale,
    },
    getCosmicObjectWithApi
  );
};

export const useBreadcrumbs = ({
  ssrAllAvailableFilters,
  pageData,
  filters,
  isOutOfStockPage,
  routerPath,
}: {
  ssrAllAvailableFilters: AvailableFilters;
  pageData: PLPPageData;
  filters: FilterValuesObject;
  isOutOfStockPage: boolean;
  routerPath: string;
}) => {
  const i18n = useIntl();
  const initialFilterKeysMap = getInitialFilterKeysMap(ssrAllAvailableFilters);
  const allAvailableFilters = useFiltersResponse();
  const { locale } = useCurrentLocale();

  const breadcrumbs = getBreadcrumbs({
    isOutOfStockPage,
    filters,
    allAvailableFilters,
    i18n,
    locale,
  });

  const title = getTitle({
    filters,
    i18n,
    routerPath,
    pageData,
  });

  const { data: headline, isLoading } = useSWR(
    [pageData, filters, initialFilterKeysMap],
    getHeadline
  );

  return {
    headline,
    breadcrumbs,
    title,
    isLoading,
  };
};
