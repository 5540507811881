import { Container } from '@finn/atoms';
import { clearClientCookie, CookieKeys } from '@finn/ui-utils';
import Alert from '@material-ui/lab/Alert';
import { useEffect, useState } from 'react';

type Props = {
  message: string;
};

export default function ActionAlerts({ message }: Props) {
  const [isOpen, setIsOpen] = useState(true);

  const clearAllCookie = () => {
    clearClientCookie(CookieKeys.PAGE_NOT_AVAILABLE);
    clearClientCookie(CookieKeys.VEHICLE_CART_NOT_AVAILABLE);
    clearClientCookie(CookieKeys.CHECKOUT_TERM_NOT_AVAILABLE);
    clearClientCookie(CookieKeys.INVALID_PAYMENT_METHOD);
    clearClientCookie(CookieKeys.STRIPE_ERROR_CODE);
    clearClientCookie(CookieKeys.DEAL_CLOSED);
  };
  useEffect(() => {
    window?.addEventListener('beforeunload', (ev) => {
      ev.preventDefault();

      return clearAllCookie();
    });

    return () => {
      clearAllCookie();
      clearClientCookie(CookieKeys.CHECKOUT_PRICE_UPDATED);
    };
  }, []);

  const closeAlert = () => {
    setIsOpen(false);
    clearAllCookie();
    clearClientCookie(CookieKeys.CHECKOUT_PRICE_UPDATED);
  };

  return isOpen ? (
    <div className="bg-lightOrange">
      <Container>
        <div className="w-full">
          <Alert
            onClose={closeAlert}
            severity="warning"
            className="!bg-lightOrange"
          >
            {message}
          </Alert>
        </div>
      </Container>
    </div>
  ) : null;
}
